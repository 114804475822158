import React, {useState, useEffect} from 'react'

import {connect} from 'react-redux'

import {Container, Header, Aside, Content, Shade} from './style'

import {changeScreen, changePowerButtonList, changeSpecialScreen, changeRegionId} from '../../store/actionCreators'

import moment from 'moment'

import {StarOutlined, StarFilled, DownOutlined, ExpandOutlined, CompressOutlined} from '@ant-design/icons';

//默认头像
import headImg from '../../img/headImg.png'

// 各页面组件

// 首页
import Home from '../../subPage/home/index'
import Home1 from '../../subPage/home1/index'

//审核管理
import Audit from "../../subPage/audit";

// 系统设置-组织机构
import Organization from '../../subPage/system/organize/index'
// 系统设置 - 用户管理
import User from '../../subPage/system/user/index'
// 系统设置 - 角色管理
import RoleManager from '../../subPage/system/role/index'
// 系统设置 - 系统字典
import Type from '../../subPage/system/type/index'
// 系统设置 - 访问失效时间设置
import ExpireTime from '../../subPage/system/expireTime/index'

//警告中心
import AlertCentre from "../../subPage/alertCentre";


//商城 - 品类管理
import Demo from '../../subPage/demo/typeManage/index'

//物资管理
import MaterialAdmin from "../../subPage/material/materialAdmin";
//物资管理 - 物资登记
import Register from '../../subPage/material/register/index'
//物资管理 - 物资洗消记录
import Decontamination from '../../subPage/material/decontamination/index'
//物资管理 - 物品库存
import Stock from '../../subPage/material/stock/index'
//物资管理 - 物品出入库
import OutAndWarehousing from '../../subPage/material/outAndWarehousing/index'

//人脸门禁
import PersonGuard from "../../subPage/personManage/personGuard";

//人管管理 - 员工管理
import Staff from '../../subPage/personManage/staffTreeAll/index'

//人管管理 - 临时访客登记
import Visitor from '../../subPage/personManage/visitorTreeAll/index'

//人管管理 - 人员洗消记录
import PeopleWash from '../../subPage/personManage/peopleWash/index'

//AI摄像头
import Area from "../../subPage/AICamera/area";
import Camera from "../../subPage/AICamera/camera";
import RiskLevel from "../../subPage/AICamera/riskLevel";
import CameraBrand from "../../subPage/AICamera/cameraBrand";

//AI报警统计
import AIWarnStatistics from "../../subPage/AIWarnStatistics";
import AIAlertRecord from "../../subPage/AIAlertRecord";

//车辆管理
import CarAdmin from "../../subPage/car/carAdmin";
import ResidentCarInfo from '../../subPage/car/residentCarInfo/index'
import TempCarInfo from '../../subPage/car/tempCarInfo/index'
import DecontaminationRecord from '../../subPage/car/decontaminationRecord/index'

//设备设置
import DeviceSet from "../../subPage/device/deviceSet";

//设备管理-智能电表
import ElectricMeter from '../../subPage/device/electricMeter/index'

//设备管理-闸机
import Gate from '../../subPage/device/gate/index'

//设备管理-智能水表
import Water from '../../subPage/device/water/index'

//设备管理-视频监控设备
import VideoMonitor from '../../subPage/device/videoMonitor/index'

//设备管理-智能花洒
import Shower from "../../subPage/device/shower";

//设备管理-臭氧
import Ozone from "../../subPage/device/ozone"

//设备管理-紫外线
import Ultraviolet from "../../subPage/device/ultraviolet"

//洗消记录
import WashRecord from "../../subPage/washRecord";

//洗消管理
import WashAdmin from "../../subPage/decontaminate/washAdmin";
//洗消管理-洗消点设置
import WashSet from '../../subPage/decontaminate/washSet/index'
//洗消管理-洗消点流程设置
import WorkFlow from '../../subPage/decontaminate/workFlowPic/index'
//洗消中心
import WashGroup from '../../subPage/decontaminate/washGroup/index'
//洗消统计
import WashTotal from "../../subPage/washTotal";

//视频监控
import VideoMonitoring from '../../subPage/videoMonitor/index'

//分享沟通
import ShareCommunication from "../../subPage/share";

//生物安全检测
import Detection from "../../subPage/detection";

//物资类型
import GoodsType from "../../subPage/material/goodsType";

//消毒间管理
import GoodsRoom from "../../subPage/material/goodsRoom";

// 404
import Error from '../../subPage/error/index'

import Request from '../../request/other-request'

import {Icon, Menu, Tabs, Button, Tooltip, message, Dropdown, Select} from 'antd'

import qing from '../../img/晴天.png'
import xiayu from '../../img/下雨.png'

const _request = new Request()

const {SubMenu} = Menu
const {TabPane} = Tabs
const {Option} = Select;

function StartUp(props) {
    const {subScreen, changeSubScreen, specialSubScreen, changePowerButtonList, changeSpecialScreen, changeRegionId, baseURL} = props
    const [currentKeys, setCurrentKeys] = useState(null) //左侧菜单当前选中项
    const [activeKey, setActiveKey] = useState('0') //tabs当前选中项
    const [menuList, setMenuList] = useState([]) //菜单列表
    const [user, setUser] = useState([]) //当前登录用户
    const [nowTime, setNowTime] = useState(moment().format('YYYY年MM月DD日 HH:mm')) //当前时间
    const [weatherData, setWeatherData] = useState({})
    const [weatherLogo, setWeatherLogo] = useState({})
    const [collect, setCollect] = useState(1)
    const [isShade, setIsShade] = useState(true)
    const [cutSystemData, setCutSystemData] = useState([])
    const [collectionList, setCollectionList] = useState([])
    const [washSpotId, setWashSpotId] = useState('')
    const [alertWashSpotId, setAlertWashSpotId] = useState('')
    const [AIData, setAIData] = useState({})
    const [washTime, setWashTime] = useState('')
    const [alertWashTime, setAlertWashTime] = useState('')
    const [washEndTime, setWashEndTime] = useState('')
    const [logoSrc, setLogoSrc] = useState('')
    const [sn, setSn] = useState('')
    const [userImg, setUserImg] = useState('')
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [flag, setFlag] = useState(false);
    const [pigFarmId, setPigFarmId] = useState('');
    const [pigFarmList, setPigFarmList] = useState([]);
    const [changeFarm, setChangeFarm] = useState(true);
    useEffect(() => {
        // setCollect(1)
        watchFullScreen()
        _request.menuCollectionList().then(res => {
            let arr = []
            res.forEach(item => {
                arr.push(item.router)
            })
            setCollectionList(arr)
        }).catch(err => {
            console.log(err)
        })
        _request.getMenu().then(res => {
            setIsShade(false)
            if (res && res.length !== 0) {
                setMenuList(res)
                let powerBtnList = []
                res.forEach(item => {
                    if (item.children && item.children.length !== 0) {
                        item.children.forEach(sub => {
                            if (sub.children && sub.children.length !== 0) {
                                sub.children.forEach(ssub => {
                                    if (ssub.permission && ssub.permission !== '') {
                                        powerBtnList.push({
                                            pageId: ssub.itemParentid,
                                            permission: ssub.permission
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
                changePowerButtonList(powerBtnList)
            }
        }).catch(err => {
            console.log(err)
        })
        _request.getSysList().then(res =>{
            console.log(res);
            let arr = res;
            res.forEach((item,index) =>{
                if (item.sysCode === 'safety'){
                    arr.splice(index,1)
                }
            })
            setCutSystemData(arr)
        })
        //用户
        _request.getUser().then(res => {
            setUser(res)
            setUserImg(res.headUrl)
            sessionStorage.setItem('user',JSON.stringify(res))
            // sessionStorage.setItem('regionId',res.regionId)
        }).catch(err => {
            console.log(err)
        })
        const timer = setInterval(() => {
            setNowTime(moment().format('YYYY年MM月DD日 HH:mm'))
        }, 1000);
        _request.getRegionWeather().then(res => {
            checkImgWidth(res.logoPath)
            if (res.weather){
                if(res.weather.search("云") != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/dy.png")
                    })
                }else if(res.weather.search("雾") != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/dww.png")
                    })
                }else if(res.weather.search("风") != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/df.png")
                    })
                }else if(res.weather.search('冷') != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/dw.png")
                    })
                }else if(res.weather.search('热') != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/gw.png")
                    })
                }else if(res.weather.search('阴')!= -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/dy.png")
                    })
                }else if(res.weather=='龙卷风'){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/ljf.png")
                    })
                }else if(res.weather.search('晴')!= -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/qt.png")
                    })
                }else if(res.weather.search("尘") != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/scb.png")
                    })
                }else if(res.weather.search("霾") != -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/wm.png")
                    })
                }else if(res.weather.search("雪")!= -1){
                    setWeatherLogo({
                        weatherLogo:require("../../img/weather/xx.png")
                    })
                }else if(res.weather.search("雨")!= -1){
                    if(res.weather=='雨夹雪'){
                        setWeatherLogo({
                            weatherLogo:require("../../img/weather/yjx.png")
                        })
                    }else{
                        setWeatherLogo({
                            weatherLogo:require("../../img/weather/xy.png")
                        })
                    }

                }
            }
            setWeatherData(res)
        })
        return () => {
            clearInterval(timer)
        }
    }, [collect])
    useEffect(() => {
        if (specialSubScreen.tabIndex && specialSubScreen.menuIndex) {
            setActiveKey(specialSubScreen.tabIndex)
            setCurrentKeys(specialSubScreen.menuIndex)
        }
    }, [specialSubScreen])
    useEffect(() => {
        getCurrentPigFarm();
    }, [pigFarmId])

    const checkImgWidth = (fileUrl) => {
        if (fileUrl) {
            const img = new Image();
            img.src = fileUrl;
            const imgHeight = document.querySelector('.home-icon-container');
            const height = imgHeight.clientHeight || 0;
            img.onload = function () {
                setFlag(img.height > height);
            };
            setLogoSrc(fileUrl)
        }
    }

    const clickMenu = (e,washSpotId,time,endTime) => {
        setWashEndTime(null)
        setAlertWashSpotId(null)
        setWashSpotId(null)
        setAIData(null)
        setAlertWashTime(null)
        if (washSpotId){
            if(e.key.split('&')[0] === '151' || e.key.split('&')[0] === '159'){
                setAlertWashSpotId(washSpotId)
            }else if (e.key.split('&')[0] === '162'){
                setSn(washSpotId)
            }else {
                setWashSpotId(washSpotId)
            }
        }
        if (e.key.split('&')[0] === '158'){
            setAIData(washSpotId)
        }
        if (time){
            if(e.key.split('&')[0] === '151' || e.key.split('&')[0] === '159'){
                setAlertWashTime(time)
            }else {
                setWashTime(time)
            }
        }
        if (endTime){
            setWashEndTime(endTime)
        }
        let screenList = [...subScreen]
        let state = false
        let isStar = false
        let id = e.key.split('&')[0]
        if (id === '144') {
            id = -1
        }
        let title = e.key.split('&')[1]
        let menuId = ''
        screenList.forEach((item, index) => {
            if (item.target === id) {
                state = true
                menuId = item.id
                screenList[index].active = true
                setActiveKey(String(index))
            } else {
                screenList[index].active = false
            }
        })
        if (!state) {
            menuList.forEach((item, index) => {
                if (item.target === id) {
                    menuId = item.id
                    isStar = item.isCollection
                }
                if (item.children && item.children.length !== 0) {
                    item.children.forEach(item2 => {
                        if (item2.target === id) {
                            menuId = item.id
                            isStar = item2.isCollection
                        }
                        if (item2.children && item2.children.length !== 0) {
                            item2.children.forEach(item3 => {
                                if (item3.target === id) {
                                    isStar = item3.isCollection
                                }
                            })
                        }
                    })
                }
            })
            screenList.push({
                title,
                target: id,
                active: true,
                id: menuId,
                isStar
            })
            setActiveKey(String(screenList.length - 1))
        }
        setCurrentKeys(e.key)
        changeSubScreen(screenList)
    }

    //  获取猪场
    const getCurrentPigFarm = () =>{
        _request.getCurrentPigFarm().then(res =>{
            setPigFarmList(res);
            let id = sessionStorage.getItem('regionId');
            if (id) {
                changeRegionId(id);
                setPigFarmId(id);
            }else {
                setPigFarmId(res[0].regionId)
                sessionStorage.setItem('regionId',res[0].regionId)
            }
            setChangeFarm(true)
        })
    }

    const selectPigFarm = (e) =>{
        setPigFarmId(e);
        sessionStorage.setItem('regionId',e);
        changeRegionId(e);
        setChangeFarm(false)
    }

    const closeScreen = (index, action) => {
        setWashEndTime(null)
        setAlertWashSpotId(null)
        setWashSpotId(null)
        setAIData(null)
        setAlertWashTime(null)
        let screenList = [...subScreen] //注意需要拷贝一份再更新
        if(screenList[index].id === 151){
            setAlertWashSpotId(null)
            setAlertWashTime(null)
        }
        if (screenList[index].id === 148){
            setWashSpotId(null)
            setWashEndTime(null)
            setWashTime(null)
        }
        if (screenList[index].active) {
            screenList[index - 1].active = true
            setActiveKey(String(Number(index) - 1))
            setCurrentKeys(screenList[Number(index) - 1].id + '&' + screenList[Number(index) - 1].title)
        } else {
            if (Number(index) < Number(activeKey)) {
                setActiveKey(String(Number(activeKey) - 1))
            }
        }
        screenList.splice(index, 1)
        changeSubScreen(screenList)
    }
    const changeTab = (index) => {
        let screenList = [...subScreen]
        screenList[Number(activeKey)].active = false
        screenList[Number(index)].active = true
        setActiveKey(index)
        setCurrentKeys(screenList[Number(index)].id + '&' + screenList[Number(index)].title)
        changeSubScreen(screenList)
    }
    //退出登录
    const logoutNow = () => {
        _request.loginOut().then(res =>{
            changeSubScreen(
                [
                    {
                        title: '首页',
                        target: -1,
                        active: true
                    }
                ]
            )
            changeSpecialScreen({})
            // props.history.push('/login')
            getLoginUrl()
        })
    }
    //重定向
    const getLoginUrl = () => {
        _request.getLoginUrl().then(res => {
            // props.history.push(res.sysLoginPath)
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('regionId')
            sessionStorage.removeItem('user')
            window.location.href=res.sysLoginPath
        })
    }


    const fullScreen = () => {
        if (!isFullScreen) {
            requestFullScreen();
            setIsFullScreen(true)
        } else {
            exitFullscreen();
            setIsFullScreen(false)
        }
    };

//进入全屏
    const requestFullScreen = () => {
        let de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
        }
    };

//退出全屏
    const exitFullscreen = () => {
        let de = document;
        if (de.exitFullscreen) {
            de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
            de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
            de.webkitCancelFullScreen();
        }
    };

//监听fullscreenchange事件
    const watchFullScreen = () => {
        document.addEventListener(
            "webkitfullscreenchange",
            function() {
                setIsFullScreen(document.webkitIsFullScreen)
            },
            false
        );
    };
    //收藏
    const enshrine = (e,type) => {
        console.log(e);
        let menuIds = [e.id]
        if (type === '2') {
            _request.delMenuCollection({
                menuIds,
            }).then(res => {
                setCollect(2)
                e.isStar = false
                message.info('取消收藏！')
            })
        } else {
            _request.menuCollection({
                menuIds
                // menuName: e.title
            }).then(res => {
                setCollect(3)
                e.isStar = true
                message.info('收藏成功！')
            })
        }
    }
    //关闭所有tab
    const closeAllTab = () => {
        changeSubScreen(
            [
                {
                    title: '首页',
                    target: -1,
                    active: true
                }
            ]
        )
        setActiveKey('0')
        setCurrentKeys('-1&首页')
        changeSpecialScreen({})
    }
    const skipSys = (item) => {
        const token = sessionStorage.getItem('token')
        if (item.sysCode === 'iot' || item.sysCode === "IoTLogin" || item.sysCode === "feeding" || item.sysCode === "unify"){
            window.open(item.sysLink+"?token=" + token, '_blank')
        }else {
            window.open(baseURL + "upms/ssoRedirectUrl?sysCode=" + item.sysCode + "&token=" + token, '_blank')
        }
    }
    //清空所有tabs的组件
    const clearAllTabs = <Button type="primary" shape="round" disabled={subScreen.length === 1?true:false} icon="close-circle" style={{marginTop: 9}} onClick={closeAllTab}>全部关闭</Button>
    return (
        <Container>
            {
                isShade?
                    <Shade></Shade>:''
            }
            <Header>
                <div className="home-icon-container"
                style={{cursor: 'pointer',display: 'flex',alignItems: 'center',justifyContent: flag?'left':'center',paddingLeft: flag?8:0}}
                onClick={clickMenu.bind(this,{key: '144&首页'})}>
                    {/*<img style={flag?{height: "100%"}:{width: '80%'}} src={logoSrc?logoSrc:require('../../img/logo3.png')} alt="润农科技生物安全"/>*/}
                </div>
                <div className="header-right">
                    {/*<div className="header-title">*/}
                    {/*    生物安全防控平台*/}
                    {/*</div>*/}
                    <div className="header-welcome">
                        <div className='header-welcome-time'>
                            {/*<span><img src={xiayu}/></span>*/}
                            <div>
                                {weatherData.weather?<img width='34' style={{marginTop: -5}} src={weatherLogo.weatherLogo} />:''}
                            </div>
                            <div style={{ display: 'flex',flexDirection: 'column',height: 50,textAlign: 'left',marginLeft: 14 }}>
                                <div style={{ height: 20,lineHeight: '30px' }}>
                                    <span style={{ fontSize: 18,color: '#358AFF',fontWeight: '500' }}>{weatherData.temperature?weatherData.temperature+"℃":''}</span>
                                    <span style={{ fontSize: 12,fontWeight: '400',marginLeft: '8px' }}>{weatherData.weather? weatherData.weather:''}</span>
                                </div>
                                <div style={{ height: 30,fontSize: '12px',lineHeight: '30px' }}>
                                    {weatherData.province?<span>{weatherData.province + weatherData.city + weatherData.district}</span>:''}
                                    <span>{nowTime}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{cursor: 'pointer'}}>
                                <Tooltip title={isFullScreen?'取消全屏':'全屏'}>
                                    <div onClick={fullScreen} style={{marginRight: 10}}>{!isFullScreen?<ExpandOutlined />:<CompressOutlined />}</div>
                                </Tooltip>
                            </div>
                            |
                            <div style={{ cursor: 'pointer',marginRight: 10,marginLeft: 10 }}>
                                <Select placeholder="请选择猪场" className="custom-select" style={{width: 150}} value={pigFarmId}
                                        onChange={(e) => selectPigFarm(e)}>
                                    {
                                        pigFarmList.map((item, index) => {
                                            return <Option key={item.regionId} value={item.regionId}>{item.regionName}</Option>
                                        })
                                    }
                                </Select>
                            </div>
                             |
                            <div style={{ cursor: 'pointer',marginRight: 10,marginLeft: 10 }}>
                                <Dropdown overlay={
                                    <Menu>
                                        {
                                            cutSystemData.map((item,index) =>{
                                                return <Menu.Item key={item.id} onClick={skipSys.bind(this, item)}>
                                                    <span>
                                                        {item.sysName}
                                                    </span>
                                                </Menu.Item>
                                            })
                                        }
                                    </Menu>}>
                                    <span>切换系统 <DownOutlined/></span>
                                </Dropdown>
                            </div>
                            |
                            <div style={{ cursor: 'pointer',marginLeft: 10 }}>
                                {/*<span>欢迎您！</span>*/}
                                <Dropdown overlay={
                                    <Menu>
                                        <Menu.Item onClick={logoutNow}>
                                            {/*<Tooltip arrowPointAtCenter mouseEnterDelay={0} title="！即将退出">*/}
                                            <span>
                                                <Icon type="poweroff"/> 退出登录
                                            </span>
                                            {/*</Tooltip>*/}
                                        </Menu.Item>
                                    </Menu>}>
                                    <div>{user && user.linkName} <img style={{margin: '0 3px'}} width='26' height='26' src={userImg?userImg:headImg} /><DownOutlined/></div>
                                </Dropdown>
                            </div>
                        </div>

                    </div>
                </div>
            </Header>
            <Aside>
                <Menu
                    mode="inline"
                    selectedKeys={[currentKeys]}
                    onClick={clickMenu}
                    style={{width: '100%', borderRight: 'none'}}
                >
                    {
                        menuList.map((item, index) => {
                            return (
                                item.children && item.children.length !== 0 ?
                                    <SubMenu
                                        key={item.target}
                                        title={
                                            <span>
                                            <span className={'iconfont ' + item.icon}
                                                  style={{marginRight: 8, width: 20, display: 'inline-block'}}></span>
                                            <span>{item.name}</span>
                                        </span>
                                        }
                                    >
                                        {
                                            item.children.map((subItem, subIndex) => {
                                                return (
                                                    subItem.children && subItem.children.length !== 0 ?
                                                        <SubMenu
                                                            key={subItem.target}
                                                            title={
                                                                <span>
                                                        <span>{subItem.name}</span>
                                                    </span>
                                                            }
                                                        >
                                                            {
                                                                subItem.children.map((subItem2, subIndex) => {
                                                                    return (
                                                                        <Menu.Item style={{color: 'rgba(0,0,0,0.6)'}}
                                                                                   key={subItem2.target + '&' + subItem2.name}>{subItem2.name}</Menu.Item>
                                                                    )
                                                                })
                                                            }
                                                        </SubMenu>
                                                        :
                                                        <Menu.Item key={subItem.target + '&' + subItem.name}>
                                                            <span>{subItem.name}</span>
                                                        </Menu.Item>
                                                )
                                            })
                                        }
                                    </SubMenu>
                                    :
                                    <Menu.Item key={item.target + '&' + item.name}>
                                        <span className={'iconfont ' + item.icon}
                                              style={{marginRight: 8, width: 20, display: 'inline-block'}}></span>
                                        <span>{item.name}</span>
                                    </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Aside>
            <Content>
                <div className="content-main">
                    <Tabs activeKey={activeKey} tabBarGutter={0} hideAdd type="editable-card"
                          tabBarExtraContent={clearAllTabs} onEdit={closeScreen} onChange={changeTab}>
                        {
                            subScreen.map((item, index) => {
                                return (
                                    <TabPane tab={
                                        item.title === '首页' ? <span>{item.title}</span> :
                                            <span>
                                            {/*<span onClick={enshrine.bind(this, item)}>*/}
                                            {
                                                !collectionList.includes(item.target) ?
                                                    <StarOutlined onClick={enshrine.bind(this, item, '1')}/> :
                                                    <StarFilled onClick={enshrine.bind(this, item, '2')}/>
                                            }
                                            {/*</span>*/}
                                            <span>{item.title}</span>
                                        </span>
                                    } key={index} closable={index === 0 ? false : true}>
                                        <div className="content-inline">
                                            {
                                                changeFarm?
                                                item.target === -1 ? ((user && user.enterpriseType == 2 && user.regionType == 5) ?
                                                    <Home1/> : <Home nextClick={clickMenu}/>) :
                                                    item.target === '4' ? <Organization pageId={4}/> :
                                                    item.target === '7' ? <User pageId={2}/> :
                                                    item.target === '3' ? <RoleManager pageId={3}/> :
                                                    item.target === '5' ? <Type/> :
                                                    item.target === '38' ? <ExpireTime/> :
                                                    item.target === '146' ? <Audit/> :


                                                    item.target === '15' ? <Demo/> :

                                                    item.target === '22' ? <Water/> :

                                                    item.target === '23' ? <Gate/> :

                                                    item.target === '136' ?  <Staff/> :
                                                    item.target === '135' ? <PersonGuard/> :
                                                    item.target === '137' ? <Visitor/> :
                                                    item.target === '138' ? <PeopleWash/> :

                                                    item.target === '139' ? <MaterialAdmin/> :
                                                    item.target === '140' ? <Register/> :
                                                    item.target === '141' ? <Decontamination/> :
                                                    item.target === '142' ? <Stock/> :
                                                    item.target === '143' ? <OutAndWarehousing/> :
                                                    item.target === '145' ? <WashGroup/> :
                                                    item.target === '147' ? <VideoMonitoring/> :
                                                    item.target === '21' ? <VideoMonitor/> :
                                                    item.target === '150' ? <Shower/> :
                                                    item.target === '151' ? <AlertCentre washSpotId={alertWashSpotId} time={alertWashTime}/> :
                                                    item.target === '153' ? <WashTotal nextClick={clickMenu}/> :
                                                    item.target === '154' ? <Ozone/> :
                                                    item.target === '155' ? <Ultraviolet/> :


                                                    item.target === '105' ? <ResidentCarInfo/> :
                                                    item.target === '152' ? <ShareCommunication/> :
                                                    item.target === '104' ? <CarAdmin/> :
                                                    item.target === '106' ? <TempCarInfo/> :
                                                    item.target === '107' ? <DecontaminationRecord/> :
                                                    item.target === '24' ? <ElectricMeter/> :
                                                    item.target === '20' ? <DeviceSet/> :


                                                    item.target === '31' ? <WashSet/> :
                                                    item.target === '30' ? <WashAdmin/> :
                                                    item.target === '148' ? <WashRecord washSpotId={washSpotId} time={washTime} endTime={washEndTime}/> :
                                                    item.target === '32' ? <WorkFlow/> :
                                                    item.target === '156' ? <GoodsType/> :
                                                    item.target === '157' ? <GoodsRoom/> :
                                                    item.target === '161' ? <Area/> :
                                                    item.target === '162' ? <Camera sn={sn}/> :
                                                    item.target === '159' ? <AIWarnStatistics nextClick={clickMenu} washSpotId={alertWashSpotId} time={alertWashTime}/> :
                                                    item.target === '158' ? <AIAlertRecord AIData={AIData} nextClick={clickMenu}/> :
                                                    item.target === '163' ? <RiskLevel/> :
                                                    item.target === '164' ? <Detection/> :
                                                    item.target === '165' ? <CameraBrand/> :
                                                    <Error/>:''
                                            }
                                        </div>
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="footer">- 由深圳市润农科技有限公司提供技术支持 -</div>
            </Content>
        </Container>
    )
}

const mapState = (state) => ({
    subScreen: state.subScreen,
    baseURL: state.baseURL,
    specialSubScreen: state.specialSubScreen
})

const mapDispatch = (dispatch) => ({
    changeSubScreen(data) {
        dispatch(changeScreen(data))
    },
    changePowerButtonList(data) {
        dispatch(changePowerButtonList(data))
    },
    changeSpecialScreen(data) {
        dispatch(changeSpecialScreen(data))
    },
    changeRegionId(data) {
        dispatch(changeRegionId(data))
    }
})

export default connect(mapState, mapDispatch)(StartUp)
